//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TrainingForm from "~/components/TrainingForm.vue";
import TwoIndicators from "~/components/results/TwoIndicators.vue";
import OneIndicator from "~/components/results/OneIndicator.vue";
export default {
  components: { TwoIndicators, TrainingForm, OneIndicator },
  data() {
    return {
      snackbar: {
        status: false,
        text: "",
      },
      resultsSettings: {
        active: false,
        token: null,
        number_indicators: null,
      },
    };
  },
  computed: {},
  methods: {
    setResults(token, number_indicators) {
      this.resultsSettings = {
        active: true,
        token: token,
        number_indicators: number_indicators,
      };
    },
  },
};
