//
//
//
//

export default {
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    actualData: {
      type: Object,
      required: true,
    },
    label: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      id: null,
    };
  },
  methods: {},
  created() {
    this.id = String(this._uid);
  },
  mounted() {
    var data = [
      {
        x: this.actualData.x,
        y: this.actualData.y,
        mode: "markers",
        text: this.actualData.labels,
        name: "Data",
        marker: {
          size: 3,
          color: this.actualData.year,
          colorscale: "Portland",
        },
      },
      {
        x: this.chartData.x,
        y: this.chartData.y,
        mode: "lines",
        name: "Prediction",
      },
    ];
    var layout = {
      margin: {
        l: 50,
        r: 50,
        b: 50,
        t: 10,
        pad: 4,
      },
      xaxis: {
        title: this.label.x,
      },
      yaxis: {
        title: this.label.y,
      },
      legend: {
        yanchor: "top",
        y: 0.99,
        xanchor: "left",
        x: 0.01,
      },
    };

    this.$Plotly.newPlot(this.id, data, layout, { displayModeBar: false });
  },
};
