//
//
//
//

export default {
  props: {
    chartData: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      id: null,
    };
  },
  methods: {},
  created() {
    this.id = String(this._uid);
  },
  methods: {},
  mounted() {
    var data = [
      {
        x: this.chartData.epoch,
        y: this.chartData.loss,
        mode: "lines",
        name: "Loss",
      },
      // {
      //   x: this.chartData.epoch,
      //   y: this.chartData.val_loss,
      //   mode: "lines",
      //   name: "Validation Loss",
      // },
    ];

    var layout = {
      margin: {
        l: 0,
        r: 0,
        b: 0,
        t: 0,
        pad: 4,
      },
      legend: {
        yanchor: "top",
        y: 0.99,
        xanchor: "left",
        x: 0.01,
      },
    };

    this.$Plotly.newPlot(this.id, data, layout);
  },
};
