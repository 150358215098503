var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.initialLoad)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',{staticClass:"card-shadow",attrs:{"height":"100%"}},[_c('v-card-title',[_vm._v("Trainingsdaten")]),_vm._v(" "),_c('v-card-text',[_c('v-radio-group',{model:{value:(_vm.radioGroup),callback:function ($$v) {_vm.radioGroup=$$v},expression:"radioGroup"}},[_c('v-radio',{attrs:{"label":"Aktie","value":'stock_id'}}),_vm._v(" "),_c('v-radio',{attrs:{"label":"Liste","value":'list_id'}})],1),_vm._v(" "),(_vm.radioGroup === 'list_id')?_c('v-select',{attrs:{"items":_vm.lists,"outlined":"","label":"Wähle eine Liste","item-text":"name","item-value":"list_id","hide-details":""},model:{value:(_vm.list_id),callback:function ($$v) {_vm.list_id=$$v},expression:"list_id"}}):_vm._e(),_vm._v(" "),(_vm.radioGroup === 'stock_id')?_c('v-select',{attrs:{"items":_vm.stocks,"outlined":"","label":"Wähle eine Aktie","item-text":"name","item-value":"stock_id","hide-details":""},model:{value:(_vm.stock_id),callback:function ($$v) {_vm.stock_id=$$v},expression:"stock_id"}}):_vm._e(),_vm._v(" "),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start-Datum","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.startDateFormatted),callback:function ($$v) {_vm.startDateFormatted=$$v},expression:"startDateFormatted"}},'v-text-field',attrs,false),on))]}}],null,false,1091184144),model:{value:(_vm.menuStartDate),callback:function ($$v) {_vm.menuStartDate=$$v},expression:"menuStartDate"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"locale":"de-de"},on:{"input":function($event){_vm.menuStartDate = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End-Datum","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.endDateFormatted),callback:function ($$v) {_vm.endDateFormatted=$$v},expression:"endDateFormatted"}},'v-text-field',attrs,false),on))]}}],null,false,2342225744),model:{value:(_vm.menuEndDate),callback:function ($$v) {_vm.menuEndDate=$$v},expression:"menuEndDate"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"locale":"de-de"},on:{"input":function($event){_vm.menuEndDate = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)],1)],1)],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',{staticClass:"card-shadow",attrs:{"height":"100%"}},[_c('v-card-text',[_c('v-checkbox',{attrs:{"label":'Nach Signal filtern'},model:{value:(_vm.selectedSignal.active),callback:function ($$v) {_vm.$set(_vm.selectedSignal, "active", $$v)},expression:"selectedSignal.active"}}),_vm._v(" "),(_vm.selectedSignal.active)?_c('div',[_c('v-select',{attrs:{"items":Object.values(_vm.signals),"outlined":"","label":"Wähle ein Signal","item-text":"name","item-value":"id"},on:{"change":function($event){return _vm.updateIndicatorSignal(
                    _vm.selectedSignal,
                    _vm.signals[_vm.selectedSignal.id]
                  )}},model:{value:(_vm.selectedSignal.id),callback:function ($$v) {_vm.$set(_vm.selectedSignal, "id", $$v)},expression:"selectedSignal.id"}}),_vm._v(" "),_vm._l((_vm.signals[_vm.selectedSignal.id]
                  .parameters),function(parameter,param_id){return _c('div',{key:param_id},[_c('v-text-field',{attrs:{"label":parameter.name,"type":"number","step":parameter.dtype === 'int' ? 1 : '',"min":parameter.range[0] *
                    parameter.representation.scaling_factor,"max":parameter.range[1] *
                    parameter.representation.scaling_factor,"rules":[
                    _vm.min(
                      parameter.range[0] *
                        parameter.representation.scaling_factor
                    ),
                    _vm.max(
                      parameter.range[1] *
                        parameter.representation.scaling_factor
                    ) ],"prefix":parameter.representation.prefix,"suffix":parameter.representation.postfix},model:{value:(_vm.selectedSignal.parameters[param_id]),callback:function ($$v) {_vm.$set(_vm.selectedSignal.parameters, param_id, $$v)},expression:"selectedSignal.parameters[param_id]"}})],1)})],2):_vm._e()],1)],1)],1)],1),_vm._v(" "),_c('v-card',{staticClass:"my-5 card-shadow"},[_c('v-card-title',[_vm._v("Indikatoren")]),_vm._v(" "),_c('v-card-text',[_c('v-row',_vm._l((_vm.selectedIndicators),function(indicator,i){return _c('v-col',{key:i,attrs:{"cols":"12","md":"6"}},[_c('v-checkbox',{attrs:{"label":'Indikator verwenden'},model:{value:(_vm.selectedIndicators[i].active),callback:function ($$v) {_vm.$set(_vm.selectedIndicators[i], "active", $$v)},expression:"selectedIndicators[i].active"}}),_vm._v(" "),(_vm.selectedIndicators[i].active)?_c('div',[_c('v-select',{attrs:{"items":Object.values(_vm.indicators),"outlined":"","label":"Wähle einen Indikator","item-text":"name","item-value":"id"},on:{"change":function($event){return _vm.updateIndicatorSignal(
                    _vm.selectedIndicators[i],
                    _vm.indicators[_vm.selectedIndicators[i].id]
                  )}},model:{value:(_vm.selectedIndicators[i].id),callback:function ($$v) {_vm.$set(_vm.selectedIndicators[i], "id", $$v)},expression:"selectedIndicators[i].id"}}),_vm._v(" "),_vm._l((_vm.indicators[
                  _vm.selectedIndicators[i].id
                ].parameters),function(parameter,param_id){return _c('v-text-field',{key:param_id,attrs:{"label":parameter.name,"type":"number","step":parameter.dtype === 'int' ? 1 : '',"min":parameter.range[0] * parameter.representation.scaling_factor,"max":parameter.range[1] * parameter.representation.scaling_factor,"rules":[
                  _vm.min(
                    parameter.range[0] *
                      parameter.representation.scaling_factor
                  ),
                  _vm.max(
                    parameter.range[1] *
                      parameter.representation.scaling_factor
                  ) ]},model:{value:(indicator.parameters[param_id]),callback:function ($$v) {_vm.$set(indicator.parameters, param_id, $$v)},expression:"indicator.parameters[param_id]"}})})],2):_vm._e()],1)}),1)],1)],1),_vm._v(" "),_c('v-card',{staticClass:"mb-5 card-shadow"},[_c('v-card-title',[_vm._v("Rendite-Perioden")]),_vm._v(" "),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-slider',{staticClass:"mt-10",attrs:{"min":"1","max":"50","label":"","thumb-color":"primary","thumb-label":"always","hide-details":""},model:{value:(_vm.return_periods),callback:function ($$v) {_vm.return_periods=$$v},expression:"return_periods"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_vm._v("\n            Ein Wert von \"20\" bedeutet, dass das Neuronale Netz darauf\n            trainiert wird, die Rendite für die nächsten 20 Handelstage zu\n            schätzen.\n          ")])],1)],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-3",attrs:{"outlined":""}},'v-btn',attrs,false),on),[_vm._v("\n          Neuronales Netz Konfigurieren\n        ")])]}}],null,false,3907853012),model:{value:(_vm.neuralNetDialog),callback:function ($$v) {_vm.neuralNetDialog=$$v},expression:"neuralNetDialog"}},[_vm._v(" "),_c('NeuralNetConfig',{attrs:{"neuralNet":_vm.neuralNet},on:{"closeDialog":function($event){_vm.neuralNetDialog = false}}})],1),_vm._v(" "),_c('div',{staticClass:"mb-15",attrs:{"align":"right"}},[_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary"},on:{"click":_vm.calculate}},[_vm._v("Training starten")])],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }