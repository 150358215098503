var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-divider',{staticClass:"my-5"}),_vm._v(" "),_c('h2',{attrs:{"id":"results"}},[_vm._v("Trainings-Ergebnisse")]),_vm._v(" "),_c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"my-3",attrs:{"align":"right"}},[_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""}},'v-btn',attrs,false),on),[_vm._v("\n          Indikatoren anpassen\n        ")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_vm._v(" "),_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Indikatoren")])],1),_vm._v(" "),_c('v-card-title',[_vm._v("Manuelle Anpassung")]),_vm._v(" "),_c('v-card-text',[_vm._l((_vm.indicators),function(indicator,i){return _c('v-text-field',{key:i,attrs:{"label":indicator.name,"type":"number","prefix":indicator.representation.prefix,"suffix":indicator.representation.postfix},model:{value:(indicator.value),callback:function ($$v) {_vm.$set(indicator, "value", $$v)},expression:"indicator.value"}})}),_vm._v(" "),_c('v-btn',{attrs:{"outlined":"","block":""},on:{"click":function($event){return _vm.getIndicatorData({ type_: 'median' })}}},[_vm._v("Auf Median zurücksetzen")])],2),_vm._v(" "),_c('v-divider',{staticClass:"ma-3"}),_vm._v(" "),_c('v-card-title',[_vm._v("Tageswerte einer Aktie beziehen")]),_vm._v(" "),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.stocks,"outlined":"","label":"Wähle eine Aktie","item-text":"name","item-value":"stock_id","hide-details":""},on:{"change":function($event){return _vm.getIndicatorData({
              type_: 'stock_id',
              stock_id_base: _vm.stock_id_base,
            })}},model:{value:(_vm.stock_id_base),callback:function ($$v) {_vm.stock_id_base=$$v},expression:"stock_id_base"}})],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":function($event){_vm.getPredictions();
            _vm.dialog = false;}}},[_vm._v("Anwenden")])],1)],1)],1),_vm._v(" "),(_vm.showResults)?_c('client-only',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"card-shadow"},[_c('v-card-title',[_vm._v("Indikator 1")]),_vm._v(" "),_c('v-card-text',[_c('Chart2D',{attrs:{"chartData":_vm.data.prediction_2d.ind_1,"actualData":{
                x: _vm.data.actual_data.x,
                y: _vm.data.actual_data.z,
                labels: _vm.data.actual_data.labels,
                year: _vm.data.actual_data.year,
              },"label":{ x: _vm.data.labels.ind_1, y: 'Rendite' }}})],1)],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"card-shadow"},[_c('v-card-title',[_vm._v("Indikator 2")]),_vm._v(" "),_c('v-card-text',[_c('Chart2D',{attrs:{"chartData":_vm.data.prediction_2d.ind_2,"label":{ x: _vm.data.labels.ind_2, y: 'Rendite' },"actualData":{
                x: _vm.data.actual_data.y,
                y: _vm.data.actual_data.z,
                labels: _vm.data.actual_data.labels,
                year: _vm.data.actual_data.year,
              }}})],1)],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticClass:"card-shadow"},[_c('v-card-title',[_vm._v("3D-Chart")]),_vm._v(" "),_c('v-card-text',[_c('Chart3D',{attrs:{"chartData":_vm.data.prediction_3d,"actualData":_vm.data.actual_data,"label":{
                x: _vm.data.labels.ind_1,
                y: _vm.data.labels.ind_2,
                z: 'Rendite',
                labels: _vm.data.actual_data.labels,
              }}})],1)],1)],1)],1)],1):_c('div',[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"max-width":"100%","type":"card"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }