//
//
//
//

export default {
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    actualData: {
      type: Object,
      required: true,
    },
    label: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      id: null,
    };
  },
  methods: {},
  created() {
    this.id = String(this._uid);
  },
  methods: {},
  mounted() {
    var data = [
      {
        x: this.actualData.x,
        y: this.actualData.y,
        z: this.actualData.z,
        text: this.actualData.labels,
        mode: "markers",
        type: "scatter3d",
        marker: {
          size: 2,
          color: this.actualData.year,
          colorscale: "Portland",
        },
      },
      {
        x: this.chartData.x,
        y: this.chartData.y,
        z: this.chartData.z,
        type: "mesh3d",
        opacity: 0.3,
      },
    ];

    var layout = {
      // autosize: true,
      scene: {
        xaxis: { title: this.label.x },
        yaxis: { title: this.label.y },
        zaxis: { title: this.label.z },
      },
      margin: {
        l: 0,
        r: 0,
        b: 0,
        t: 0,
        pad: 4,
      },
    };
    this.$Plotly.newPlot(this.id, data, layout);
  },
};
