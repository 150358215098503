//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    stockInfo: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {};
  },
  methods: {},
  created() {},
  methods: {},
};
