//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NeuralNetConfig from "~/components/NeuralNetConfig.vue";
export default {
  components: { NeuralNetConfig },
  async fetch() {
    let { data } = await this.$axios.get("/api/initial_request");
    this.stock_id = data.stocks[0].stock_id;
    this.list_id = data.lists[0].list_id;
    this.lists = data.lists;
    this.indicators = data.indicators;
    this.signals = data.signals;
    this.stocks = data.stocks;
    this.initialLoad = true;
  },
  data() {
    return {
      startDate: "2015-01-01",
      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menuStartDate: false,
      menuEndDate: false,
      stock_id: null,
      list_id: null,
      lists: null,
      indicators: null,
      signals: null,
      stocks: null,
      initialLoad: false,
      radioGroup: "stock_id",
      return_periods: 10,
      loading: false,
      neuralNetDialog: false,
      selectedSignal: {
        id: "s_new_high",
        active: true,
        parameters: { periods: 250 },
      },
      selectedIndicators: [
        {
          id: "ind_ma_distance",
          active: true,
          parameters: { periods: 200 },
        },
        {
          id: "ind_perf",
          active: false,
          parameters: { periods: 200 },
        },
      ],
      neuralNet: {
        epochs: 50,
        number_hidden_layers: 2,
        nodes: [8, 8, 8],
      },
    };
  },
  computed: {
    startDateFormatted() {
      return this.formatDate(this.startDate);
    },
    endDateFormatted() {
      return this.formatDate(this.endDate);
    },
  },
  methods: {
    min(minNum) {
      if (minNum === null) {
        return true;
      }
      return (v) => v >= minNum || `Mindestwert ${minNum}`;
    },
    max(maxNum) {
      if (maxNum === null) {
        return true;
      }
      return (v) => v <= maxNum || `Maximalwert: ${maxNum}`;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    updateIndicatorSignal(actual, meta) {
      let parameters = {};
      Object.keys(meta.parameters).forEach((p) => {
        parameters[p] =
          Number(meta.parameters[p].default) *
          meta.parameters[p].representation.scaling_factor;
      });
      actual.parameters = parameters;
    },
    reTransformSignalIndicator(actual, meta) {
      let actualCopy = JSON.parse(JSON.stringify(actual));
      Object.keys(actualCopy.parameters).forEach((p) => {
        actualCopy.parameters[p] =
          Number(actualCopy.parameters[p]) /
          meta.parameters[p].representation.scaling_factor;
      });
      return actualCopy;
    },
    async calculate() {
      this.$emit("trainingStarted");
      this.loading = true;

      let nodes = this.neuralNet.nodes.slice(
        0,
        this.neuralNet.number_hidden_layers
      );

      let signals = [];
      if (this.selectedSignal.active) {
        signals.push(
          this.reTransformSignalIndicator(
            this.selectedSignal,
            this.signals[this.selectedSignal.id]
          )
        );
      }

      let body = {
        indicators: this.selectedIndicators
          .filter((i) => i.active)
          .map((i) =>
            this.reTransformSignalIndicator(i, this.indicators[i.id])
          ),
        signals: signals,
        return_periods: Number(this.return_periods),
        epochs: Number(this.neuralNet.epochs),
        nodes: nodes,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      if (this.radioGroup === "list_id") {
        body["list_id"] = Number(this.list_id);
      } else {
        body["stock_id"] = Number(this.stock_id);
      }

      let response = await this.$axios.post("/api", body, {
        timeout: 5 * 60 * 1000,
      });
      this.loading = false;

      if (response.status !== 200) {
        return;
      }

      let data = response.data;
      this.$emit("trainingSuccessful", data.id, data.number_indicators);
      this.loading = false;
    },
  },
};
