//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    neuralNet: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        min(minNum) {
          if (minNum === null) {
            return true;
          }
          return (v) => v >= minNum || `Mindestwert ${minNum}`;
        },
        max(maxNum) {
          if (maxNum === null) {
            return true;
          }
          return (v) => v <= maxNum || `Maximalwert: ${maxNum}`;
        },
      },
    };
  },
  created() {},
  methods: {
    closeDialog(event) {
      this.$emit("closeDialog");
    },
  },
};
