//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Chart2D from "~/components/Chart2D.vue";
import StockInfo from "~/components/results/StockInfo.vue";
export default {
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  components: {
    Chart2D,
    StockInfo,
  },
  async fetch() {
    await this.getPredictions();
  },
  data() {
    return {
      showResults: false,
      data: {},
      dialog: false,
    };
  },
  methods: {
    async getPredictions() {
      this.showResults = false;

      let { data } = await this.$axios.post(
        "/api/one-indicator/" + this.token,
        {}
      );
      this.data = data;
      this.showResults = true;
      if (process.client) {
        var elmnt = document.getElementById("results");
        elmnt.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
