//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Chart3D from "~/components/Chart3D.vue";
import Chart2D from "~/components/Chart2D.vue";
import ChartHistory from "~/components/ChartHistory.vue";
export default {
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  components: {
    Chart3D,
    Chart2D,
    ChartHistory,
  },
  async fetch() {
    let { data } = await this.$axios.get("/api/initial_request");
    this.indicatorsMeta = data.indicators;
    this.stocks = data.stocks;
    this.stock_id_base = data.stocks[0].stock_id;
    await this.getIndicatorData({ type_: "median" });
    await this.getPredictions();
  },
  data() {
    return {
      showResults: false,
      indicatorsMeta: {},
      stocks: [],
      stock_id_base: null,
      indicators: [],
      data: {},
      dialog: false,
    };
  },
  methods: {
    async getIndicatorData(payload) {
      let { data } = await this.$axios.post(
        "/api/indicator-values/" + this.token,
        payload
      );
      let indicators = data.indicators;

      indicators.forEach((indicator) => {
        indicator.value =
          indicator.value *
          this.indicatorsMeta[indicator.id].representation.scaling_factor;
        indicator.representation =
          this.indicatorsMeta[indicator.id].representation;
      });

      this.indicators = indicators;
    },
    async getPredictions() {
      this.showResults = false;

      let indicators = JSON.parse(JSON.stringify(this.indicators));

      indicators.forEach((indicator) => {
        indicator.value =
          indicator.value /
          this.indicatorsMeta[indicator.id].representation.scaling_factor;
      });

      let { data } = await this.$axios.post(
        "/api/two-indicators/" + this.token,
        {
          indicators: indicators,
        }
      );
      this.data = data;
      this.showResults = true;
      if (process.client) {
        var elmnt = document.getElementById("results");
        elmnt.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
